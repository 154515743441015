import React from "react"
import Layout from "../components/layout"
import Header1 from "../components/header1"
import TakenSVG from "../assets/svgr/taken"

export default function Imprint() {
  return (
    <Layout>
      <section className="bg-white py-20">
        <div className="container max-w-5xl mx-auto m-8">
          <Header1 headerText="This page is gone" />
          
          <div className="container mx-auto mt-16" style={{"maxWidth":"300px"}} >
            <TakenSVG color="#58B8BC" />
          </div>
        </div>
      </section>
    </Layout>
  )
}
